import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Logo = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="8" fill="#06b6d4" />
    <path d="M12 20L20 12L28 20L20 28L12 20Z" stroke="white" strokeWidth="2" />
    <path d="M20 12V28" stroke="white" strokeWidth="2" />
    <path d="M12 20H28" stroke="white" strokeWidth="2" />
  </svg>
);

const Navbar = ({ isHomePage = false }) => {
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        // Only hide when scrolled down more than 100px
        setIsNavVisible(false);
      } else {
        setIsNavVisible(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const navItems = isHomePage
    ? ["Home", "Journey", "Skills", "Contact"].map((item) => ({
        text: item,
        link: `#${item.toLowerCase()}`,
      }))
    : [
        { text: "Home", link: "/" },
        { text: "Imprint", link: "/imprint" },
        { text: "Privacy Policy", link: "/privacy-policy" },
        { text: "Terms of Service", link: "/terms-of-service" },
      ];

  return (
    <motion.header
      className={`fixed top-0 left-0 right-0 z-50 bg-gray-900 bg-opacity-80 backdrop-blur-md transition-all duration-300 ${
        isNavVisible ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1 items-center">
            <Link to="/" className="flex items-center">
              <span className="sr-only">Jan Swoboda</span>
              <Logo />
              <span className="ml-2 text-xl font-semibold text-white">
                Swoboda
              </span>
            </Link>
          </div>
          <nav className="hidden md:flex space-x-10">
            {navItems.map((item) => (
              <Link
                key={item.text}
                to={item.link}
                className="text-base font-medium text-white hover:text-blue-500 transition-colors"
              >
                {item.text}
              </Link>
            ))}
          </nav>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-blue-500 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Open menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isMenuOpen ? "block" : "hidden"} md:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {navItems.map((item) => (
            <Link
              key={item.text}
              to={item.link}
              className="text-white hover:bg-gray-800 hover:text-blue-500 block px-3 py-2 rounded-md text-base font-medium"
              onClick={() => setIsMenuOpen(false)}
            >
              {item.text}
            </Link>
          ))}
        </div>
      </div>
    </motion.header>
  );
};

export default Navbar;
