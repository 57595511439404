import React from "react";
import Navbar from "./Navbar";

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Navbar />
      <div className="pt-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto bg-gray-800 shadow-md rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">1. Introduction</h2>
              <p className="mb-4">
                This Privacy Policy explains how Jan Swoboda ("we," "us," or
                "our") collects, uses, discloses, and protects your personal
                information when you visit our website or use our services. This
                policy is designed to comply with the General Data Protection
                Regulation (GDPR), the California Online Privacy Protection Act
                (CalOPPA), the California Consumer Privacy Act (CCPA), and the
                California Privacy Rights Act (CPRA).
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                2. Information We Collect
              </h2>
              <p className="mb-4">
                We may collect the following types of personal information:
              </p>
              <ul className="list-disc pl-5 mb-4">
                <li>
                  Contact information (e.g., name, email address, phone number)
                </li>
                <li>
                  Usage data (e.g., IP address, browser type, pages visited)
                </li>
                <li>
                  Device information (e.g., device type, operating system)
                </li>
                <li>
                  Communication data (e.g., messages sent through our contact
                  form)
                </li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                3. How We Use Your Information
              </h2>
              <p className="mb-4">
                We use your personal information for the following purposes:
              </p>
              <ul className="list-disc pl-5 mb-4">
                <li>To provide and maintain our services</li>
                <li>To communicate with you about our services</li>
                <li>To improve our website and services</li>
                <li>To comply with legal obligations</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                4. Data Sharing and Disclosure
              </h2>
              <p className="mb-4">
                We do not sell your personal information. We may share your
                information with third-party service providers who help us
                operate our website and provide our services. These service
                providers are obligated to protect your information and may not
                use it for any other purpose.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                5. Your Rights and Choices
              </h2>
              <p className="mb-4">
                Depending on your location, you may have the following rights
                regarding your personal information:
              </p>
              <ul className="list-disc pl-5 mb-4">
                <li>
                  Right to access and receive a copy of your personal
                  information
                </li>
                <li>
                  Right to rectify inaccurate or incomplete personal information
                </li>
                <li>Right to erasure (or "right to be forgotten")</li>
                <li>
                  Right to restrict processing of your personal information
                </li>
                <li>Right to data portability</li>
                <li>
                  Right to object to processing of your personal information
                </li>
                <li>Right to withdraw consent at any time</li>
              </ul>
              <p className="mb-4">
                To exercise these rights, please contact us using the
                information provided in the "Contact Us" section below.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                6. California Privacy Rights
              </h2>
              <p className="mb-4">
                If you are a California resident, you have additional rights
                under the CCPA and CPRA, including:
              </p>
              <ul className="list-disc pl-5 mb-4">
                <li>
                  Right to know what personal information is collected, used,
                  shared, or sold
                </li>
                <li>Right to delete personal information held by businesses</li>
                <li>Right to opt-out of the sale of personal information</li>
                <li>Right to non-discrimination when exercising CCPA rights</li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">7. Data Security</h2>
              <p className="mb-4">
                We implement appropriate technical and organizational measures
                to protect your personal information against unauthorized or
                unlawful processing, accidental loss, destruction, or damage.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                8. International Data Transfers
              </h2>
              <p className="mb-4">
                Your information may be transferred to and processed in
                countries other than your country of residence. We ensure that
                appropriate safeguards are in place to protect your information
                when it is transferred internationally.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                9. Children's Privacy
              </h2>
              <p className="mb-4">
                Our services are not intended for children under the age of 16.
                We do not knowingly collect personal information from children
                under 16. If you become aware that a child has provided us with
                personal information, please contact us, and we will take steps
                to delete such information.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                10. Changes to This Privacy Policy
              </h2>
              <p className="mb-4">
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page and updating the "Last updated" date at the top of
                this Privacy Policy.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">11. Contact Us</h2>
              <p className="mb-4">
                If you have any questions about this Privacy Policy or our data
                practices, please contact us at:
              </p>
              <p className="mb-2">Jan Christian Swoboda</p>
              <p className="mb-2">Dotzheimerstr. 152 / 309</p>
              <p className="mb-2">65197 Wiesbaden</p>
              <p className="mb-2">Email: jan_swoboda@hotmail.com</p>
              <p className="mb-2">Phone: 0152 23132471</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
