import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Imprint from "./pages/Imprint";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Logo from "./components/Logo";

function App() {
  return (
    <Router>
      <Helmet>
        <title>Jan Swoboda</title>
        <meta
          name="description"
          content="Personal website of Jan Swoboda - Law Student, Entrepreneur, and Developer."
        />

        {/* OpenGraph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://janswoboda.com/" />
        <meta
          property="og:title"
          content="Jan Swoboda - Law Student, Entrepreneur, Developer"
        />
        <meta
          property="og:description"
          content="Personal website of Jan Swoboda - Law Student, Entrepreneur, and Developer."
        />
        <meta
          property="og:image"
          content="https://janswoboda.com/og-image.png"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://janswoboda.com/" />
        <meta
          property="twitter:title"
          content="Jan Swoboda - Law Student, Entrepreneur, Developer"
        />
        <meta
          property="twitter:description"
          content="Personal website of Jan Swoboda - Law Student, Entrepreneur, and Developer."
        />
        <meta
          property="twitter:image"
          content="https://janswoboda.com/og-image.png"
        />

        {/* Favicon */}
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      </Helmet>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/imprint" element={<Imprint />} />
      </Routes>
    </Router>
  );
}

export default App;
