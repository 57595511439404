import React from "react";
import Navbar from "./Navbar";

const Imprint = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Navbar />
      <div className="pt-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto bg-gray-800 shadow-md rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold mb-6">Impressum</h1>
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                Angaben gemäß § 5 TMG
              </h2>
              <p className="mb-2">Jan Christian Swoboda</p>
              <p className="mb-2">Dotzheimerstr. 152 / 309</p>
              <p className="mb-4">65197 Wiesbaden</p>
            </section>
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">Kontakt</h2>
              <p className="mb-2">Telefon: 0152 23132471</p>
              <p className="mb-2">E-Mail: jan_swoboda@hotmail.com</p>
            </section>
            {/* Add more sections as required by German law */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
