import React from "react";
import Navbar from "./Navbar";

const TermsOfService = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <Navbar />
      <div className="pt-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto bg-gray-800 shadow-md rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                1. Acceptance of Terms
              </h2>
              <p className="mb-4">
                By accessing or using the website of Jan Swoboda ("we," "us," or
                "our"), you agree to comply with and be bound by these Terms of
                Service. If you do not agree to these terms, please do not use
                our website.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                2. Changes to Terms
              </h2>
              <p className="mb-4">
                We reserve the right to modify these terms at any time. We will
                always post the most current version on our website. By
                continuing to use the site after changes have been made, you
                agree to be bound by the revised terms.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                3. Use of the Website
              </h2>
              <p className="mb-4">
                You agree to use our website only for lawful purposes and in a
                way that does not infringe the rights of, restrict or inhibit
                anyone else's use and enjoyment of the website. Prohibited
                behavior includes harassing or causing distress or inconvenience
                to any other user, transmitting obscene or offensive content or
                disrupting the normal flow of dialogue within our website.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                4. Intellectual Property
              </h2>
              <p className="mb-4">
                The content on our website, including without limitation, the
                text, software, scripts, graphics, photos, sounds, music,
                videos, interactive features and the like ("Content") and the
                trademarks, service marks and logos contained therein ("Marks"),
                are owned by or licensed to us, subject to copyright and other
                intellectual property rights under German, European, and foreign
                laws and international conventions.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">5. User Content</h2>
              <p className="mb-4">
                You retain all of your ownership rights in your content.
                However, by submitting the content to us, you grant us a
                worldwide, non-exclusive, royalty-free, sublicenseable and
                transferable license to use, reproduce, distribute, prepare
                derivative works of, display, and perform the content in
                connection with our website and business.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">6. Privacy</h2>
              <p className="mb-4">
                Your use of our website is also governed by our Privacy Policy.
                Please review our Privacy Policy, which also governs the site
                and informs users of our data collection practices.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                7. Disclaimer of Warranties
              </h2>
              <p className="mb-4">
                Your use of our website is at your sole risk. The website is
                provided on an "AS IS" and "AS AVAILABLE" basis. We disclaim all
                warranties of any kind, whether express or implied, including,
                but not limited to the implied warranties of merchantability,
                fitness for a particular purpose and non-infringement.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                8. Limitation of Liability
              </h2>
              <p className="mb-4">
                We shall not be liable for any indirect, incidental, special,
                consequential or punitive damages, including without limitation,
                loss of profits, data, use, goodwill, or other intangible
                losses, resulting from (i) your access to or use of or inability
                to access or use the website; (ii) any conduct or content of any
                third party on the website; (iii) any content obtained from the
                website; and (iv) unauthorized access, use or alteration of your
                transmissions or content, whether based on warranty, contract,
                tort (including negligence) or any other legal theory, whether
                or not we have been informed of the possibility of such damage.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">
                9. Indemnification
              </h2>
              <p className="mb-4">
                You agree to defend, indemnify and hold harmless Jan Swoboda and
                its licensee and licensors, and their employees, contractors,
                agents, officers and directors, from and against any and all
                claims, damages, obligations, losses, liabilities, costs or
                debt, and expenses (including but not limited to attorney's
                fees), resulting from or arising out of a) your use and access
                of the website, by you or any person using your account and
                password; b) a breach of these Terms, or c) Content posted on
                the website.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">10. Governing Law</h2>
              <p className="mb-4">
                These Terms shall be governed and construed in accordance with
                the laws of Germany, without regard to its conflict of law
                provisions. Our failure to enforce any right or provision of
                these Terms will not be considered a waiver of those rights.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">11. Severability</h2>
              <p className="mb-4">
                If any provision of these Terms is held to be unenforceable or
                invalid, such provision will be changed and interpreted to
                accomplish the objectives of such provision to the greatest
                extent possible under applicable law and the remaining
                provisions will continue in full force and effect.
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-3">12. Contact Us</h2>
              <p className="mb-4">
                If you have any questions about these Terms, please contact us
                at:
              </p>
              <p className="mb-2">Jan Christian Swoboda</p>
              <p className="mb-2">Dotzheimerstr. 152 / 309</p>
              <p className="mb-2">65197 Wiesbaden</p>
              <p className="mb-2">Email: jan_swoboda@hotmail.com</p>
              <p className="mb-2">Phone: 0152 23132471</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
