import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <p>&copy; {currentYear} Jan Swoboda. All rights reserved.</p>
          </div>
          <nav className="flex flex-wrap justify-center md:justify-end">
            <Link
              to="/privacy-policy"
              className="mx-3 hover:text-blue-400 transition-colors"
            >
              Datenschutzerklärung
            </Link>
            <Link
              to="/terms-of-service"
              className="mx-3 hover:text-blue-400 transition-colors"
            >
              AGB
            </Link>
            <Link
              to="/imprint"
              className="mx-3 hover:text-blue-400 transition-colors"
            >
              Impressum
            </Link>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
