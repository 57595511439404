import React from "react";
import { motion } from "framer-motion";

const Timeline = ({ children }) => {
  return (
    <div className="relative">
      <div className="absolute left-9 top-0 h-full w-0.5 bg-blue-500" />
      <div className="space-y-12">{children}</div>
    </div>
  );
};

const TimelineEvent = ({ year, title }) => {
  return (
    <motion.div
      className="flex items-center"
      initial={{ opacity: 0, x: -50 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div
        className="w-20 h-20 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold text-xl z-10"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {year}
      </motion.div>
      <motion.div
        className="flex-1 ml-6 bg-white bg-opacity-5 backdrop-blur-lg rounded-lg p-4 shadow-lg"
        whileHover={{ scale: 1.02 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <h3 className="text-lg font-semibold">{title}</h3>
      </motion.div>
    </motion.div>
  );
};

export { Timeline, TimelineEvent };
