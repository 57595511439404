import React from "react";
import { motion } from "framer-motion";
import { FaPodcast, FaGlobe, FaYoutube, FaBook } from "react-icons/fa";

const categories = [
  {
    title: "Podcasts",
    icon: FaPodcast,
    items: [
      { name: "All-In Podcast", url: "https://www.allinpodcast.co/" },
      {
        name: "FAZ Einspruch",
        url: "https://www.faz.net/einspruch/",
      },
      { name: "Naval", url: "https://nav.al/podcast" },
      {
        name: "ToKCast",
        url: "https://brettroberthall.podbean.com",
      },
      { name: "GaryVee", url: "https://www.garyvaynerchuk.com/podcast/" },
      {
        name: "Handelsblatt Disrupt",
        url: "https://www.handelsblatt.com/audio/disrupt-podcast/",
      },
    ],
  },
  {
    title: "Websites",
    icon: FaGlobe,
    items: [
      { name: "A Smart Bear", url: "https://longform.asmartbear.com/" },
      { name: "Hacker News", url: "https://news.ycombinator.com/" },
      { name: "MacRumors", url: "https://www.macrumors.com/" },
      { name: "PG Essays", url: "http://www.paulgraham.com/articles.html" },
    ],
  },
  {
    title: "YouTube",
    icon: FaYoutube,
    items: [
      { name: "Lex Fridman", url: "https://www.youtube.com/user/lexfridman" },
      {
        name: "My First Million",
        url: "https://www.youtube.com/@MyFirstMillionPod",
      },
      { name: "ColdFusion", url: "https://www.youtube.com/user/coldfustion" },
      { name: "WALULIS", url: "https://www.youtube.com/user/walulissiehtfern" },
      {
        name: "Leo Cadeau",
        url: "https://www.youtube.com/@LeoCadeau",
      },
      {
        name: "David Perell",
        url: "https://www.youtube.com/@DavidPerellChannel",
      },
      { name: "Veritasium", url: "https://www.youtube.com/user/1veritasium" },
    ],
  },
  {
    title: "Books",
    icon: FaBook,
    items: [
      {
        name: "The Beginning of Infinity by David Deutsch",
        url: "https://www.goodreads.com/book/show/10483171-the-beginning-of-infinity",
      },
      {
        name: "Indistractable by Nir Eyal",
        url: "https://www.goodreads.com/book/show/44595007-indistractable",
      },
      {
        name: "How to Win Friends and Influence People",
        url: "https://www.goodreads.com/book/show/4865.How_to_Win_Friends_and_Influence_People",
      },
      {
        name: "The Rational Optimist",
        url: "https://www.goodreads.com/book/show/7776209-the-rational-optimist",
      },
      {
        name: "Skin in the Game",
        url: "https://www.goodreads.com/book/show/36064445-skin-in-the-game",
      },
    ],
  },
];

const DigitalInterestsSection = () => {
  return (
    <section className="py-20 bg-gradient-to-br from-gray-900 to-indigo-900">
      <div className="container mx-auto px-6">
        <motion.h2
          className="text-4xl font-bold mb-16 text-center text-white"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Digital Content I Love
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {categories.map((category, index) => (
            <motion.div
              key={category.title}
              className="bg-white bg-opacity-10 rounded-lg p-6 backdrop-blur-lg"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="flex items-center mb-4">
                <category.icon className="text-3xl mr-3 text-blue-400" />
                <h3 className="text-2xl font-semibold text-white">
                  {category.title}
                </h3>
              </div>
              <ul className="space-y-2">
                {category.items.map((item, itemIndex) => (
                  <motion.li
                    key={item.name}
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3, delay: itemIndex * 0.05 }}
                  >
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-300 hover:text-blue-100 transition-colors duration-200"
                    >
                      {item.name}
                    </a>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DigitalInterestsSection;
