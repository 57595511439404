import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import Particles from "./Particles";
import { Timeline, TimelineEvent } from "./Timeline";
import HeroImage from "../assets/hero.png";
import { SkillCard } from "./SkillCard";
import ContactSection from "./ContactSection";
import SkillsSection from "./SkillsSection";
import Footer from "./Footer";
import Navbar from "./Navbar";
import DigitalInterestsSection from "./DigitalInterestsSection";
import WorldviewGlobeSection from "./WorldviewGlobeSection";

const ColorTheme = {
  primary: "#06b6d4",
  secondary: "#3b82f6",
  tertiary: "#6366f1",
};

const HomePage = () => {
  return (
    <div className="bg-gray-900 text-white">
      <Navbar isHomePage={true} />

      <main>
        <section
          id="home"
          className="relative h-screen flex items-center justify-center p-6 overflow-hidden"
        >
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url(${HeroImage})`,
              filter: "brightness(0.4)",
            }}
          />

          <div className="relative z-10 text-center w-full max-w-3xl mx-auto px-4">
            <motion.h2
              className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold mb-6"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Jan Swoboda
            </motion.h2>
            <motion.p
              className="text-xl sm:text-2xl md:text-3xl mb-10"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <span style={{ color: ColorTheme.primary }}>Law Student</span> |
              <span style={{ color: ColorTheme.secondary }}> Entrepreneur</span>{" "}
              |<span style={{ color: ColorTheme.tertiary }}> Developer</span>
            </motion.p>
            <motion.a
              href="#journey"
              className="bg-blue-500 text-white px-8 py-4 rounded-full text-xl font-semibold hover:bg-opacity-80 transition-all inline-block"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              Discover My World
            </motion.a>
          </div>
          {/* <Canvas className="absolute inset-0">
            <Particles count={1000} cursorPosition={cursorPosition} />
          </Canvas> */}
        </section>

        <section
          id="journey"
          className="py-20 bg-gradient-to-br from-gray-900 to-blue-900"
        >
          <div className="container mx-auto px-6">
            <motion.h2
              className="text-4xl font-bold mb-16 text-center"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              My Journey
            </motion.h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <motion.div
                className="space-y-6"
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <p>
                  Born in Schwabenland, Germany, on February 12, 2001, I grew up
                  in Ludwigsburg with a unique perspective shaped by my Romanian
                  heritage. This dual cultural background has been a driving
                  force in my life, giving me a broader worldview and the
                  ability to bridge different perspectives.
                </p>
                <p>
                  My passion for innovation and entrepreneurship blossomed
                  early. At 16, I led our school's startup, diving headfirst
                  into the world of business with a creative venture in printed
                  toilet paper. This experience ignited a spark that would shape
                  my future endeavors.
                </p>
                <p>
                  The world of sneaker reselling caught my attention at 17, but
                  it was the entrepreneurial aspect that truly captivated me. I
                  found myself running a "cook group," honing my skills in
                  operations and community management.
                </p>
                <p>
                  After completing my Abitur, I enrolled in the EBS Law School
                  in Wiesbaden, where I continue to pursue my legal education.
                  The COVID-19 pandemic, while challenging, opened new doors. I
                  taught myself coding, merging my legal background with tech
                  skills to explore the exciting intersection of law and
                  technology.
                </p>
                <p>
                  This unique combination led to a series of software startup
                  ventures. Among our many ideas, we created the #1 ranked NFT
                  app in the US and German App Stores, collaborated on an NFT
                  wine bottle project, and developed "Shmett," a positivity app.
                  Today, I'm focused on balancing my entrepreneurial spirit with
                  my commitment to excelling in my upcoming state exam in
                  February 2025.
                </p>
              </motion.div>
              <div className="relative">
                <Timeline>
                  <TimelineEvent
                    year={2001}
                    title="Born in Schwabenland, Germany"
                  />
                  <TimelineEvent
                    year={2017}
                    title="Led school startup (printed toilet paper)"
                  />
                  <TimelineEvent
                    year={2018}
                    title="Entered sneaker reselling & ran a 'cook group'"
                  />
                  <TimelineEvent
                    year={2019}
                    title="Enrolled in EBS Law School, Wiesbaden"
                  />
                  <TimelineEvent
                    year={2020}
                    title="Self-taught coding during pandemic"
                  />
                  <TimelineEvent
                    year={2021}
                    title="#1 ranked NFT app in US & German App Stores"
                  />
                  <TimelineEvent
                    year={2024}
                    title="Developed 'Shmett' positivity app"
                  />
                  <TimelineEvent year={2025} title="Upcoming state exam" />
                </Timeline>
              </div>
            </div>
          </div>
        </section>

        <SkillsSection />

        <DigitalInterestsSection />

        <WorldviewGlobeSection />

        <ContactSection />

        <Footer />
      </main>
    </div>
  );
};

export default HomePage;
