import React from "react";
import { motion } from "framer-motion";

const SkillCard = ({ title, description, icon }) => (
  <motion.div
    className="bg-gray-800 rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    layout
  >
    <div className="text-4xl mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </motion.div>
);

const SkillsSection = () => {
  const skills = [
    {
      title: "Legal Expertise",
      description:
        "Solid foundation in law with practical experience in banking law from a boutique firm in Frankfurt.",
      icon: "⚖️",
    },
    {
      title: "React Native Development",
      description:
        "Proficient in building cross-platform mobile applications using React Native.",
      icon: "📱",
    },
    {
      title: "React Web Development",
      description:
        "Experienced in creating responsive and dynamic websites using React and modern web technologies.",
      icon: "💻",
    },
    {
      title: "Entrepreneurship",
      description:
        "Proven track record of initiating and managing successful startup ventures.",
      icon: "🚀",
    },
    {
      title: "Problem Solving",
      description:
        "Adept at analyzing complex situations and developing innovative solutions.",
      icon: "🧩",
    },
    {
      title: "Cross-disciplinary Thinking",
      description:
        "Unique ability to bridge legal knowledge with technological innovations.",
      icon: "🌉",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <section
      id="skills"
      className="py-20 bg-gradient-to-br from-gray-900 to-blue-900"
    >
      <div className="container mx-auto px-6">
        <motion.h2
          className="text-4xl font-bold mb-16 text-center"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, type: "spring" }}
        >
          My Skills
        </motion.h2>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {skills.map((skill, index) => (
            <motion.div key={index} variants={itemVariants}>
              <SkillCard {...skill} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default SkillsSection;
